<template>
    <div>
        <v-skeleton-loader
            v-if="dataLoading && Object.keys(paymentConfig).length === 0"
            type="card, button"
        >
        </v-skeleton-loader>
        <!-- <psi-content-loader
            v-if="dataLoading && Object.keys(paymentConfig).length === 0"
        ></psi-content-loader> -->
        <div v-else>
            <h3 v-if="$vuetify.breakpoint.smAndDown" class="mb-2">
                {{ $t("applications.application-payment") }}
            </h3>
            <v-card flat :disabled="dataLoading">
                <form-text-field
                    ref="cardholder_name"
                    :placeholder="$t('applications.name-on-card')"
                    :rules="[
                        (v) => !!v || $t('applications.name-on-card-required'),
                    ]"
                    required
                    icon="person"
                    :label="$t('applications.name-on-card')"
                    name="cardholder_name"
                    v-model="cardholder_name"
                ></form-text-field>
                <form-text-field
                    :label="$t('applications.receipt-email')"
                    :placeholder="$t('applications.enter-email')"
                    icon="mail_outline"
                    name="email"
                    required
                    :hint="$t('applications.receipt-email-hint')"
                    persistent-hint
                    v-model="cardholder_email"
                    :rules="[
                        (v) =>
                            v.length == 0 ||
                            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                                v
                            ) ||
                            $t('applications.email-invalid'),
                    ]"
                ></form-text-field>
                <div v-if="mode !== 'production'" class="d-flex">
                    <v-spacer></v-spacer>
                    <v-btn
                        class="mb-n4"
                        fab
                        top
                        small
                        right
                        color="warning darken-2"
                        @click.stop="fill"
                        ><v-icon>mdi-form-select</v-icon></v-btn
                    >
                </div>
                <form-credit-card
                    ref="credit-card"
                    :stripe-key="paymentConfig.stripe_key"
                    :email="cardholder_email"
                    :name="cardholder_name"
                    @card:input="cardValid = $event"
                ></form-credit-card>

                <v-row>
                    <v-col cols="1" class="mr-2">
                        <v-checkbox
                            id="authorize"
                            color="primary"
                            style="margin-top: -2px"
                            v-model="agree"
                        ></v-checkbox>
                    </v-col>
                    <v-col>
                        <label for="authorize" class="font-weight-light body-1">
                            I hereby authorize
                            <span class="font-weight-bold"
                                >PACIFIC SCREENING, INC</span
                            >
                            to charge the credit card a non-refundable fee of
                            <span class="font-weight-bold">{{
                                $dollarFormat(price)
                            }}</span>
                        </label>
                    </v-col>
                </v-row>
            </v-card>
            <v-alert :value="error.length != 0" type="error">{{
                error
            }}</v-alert>

            <v-layout row align-center class="mb-2 mt-2">
                <v-spacer></v-spacer>
                <v-btn
                    @click.prevent="submitPayment"
                    color="secondary"
                    :disabled="paymentDisabled"
                    :loading="dataLoading"
                >
                    <v-icon class="mr-1">lock</v-icon>
                    {{
                        $t("applications.secure-pay", {
                            price: $dollarFormat(price),
                        })
                    }}
                </v-btn>
            </v-layout>
            <v-row>
                <v-col cols="12">
                    <psi-content-loader
                        circle
                        v-if="dataLoading"
                        type="circular"
                    ></psi-content-loader>
                </v-col>
            </v-row>
        </div>
        <v-dialog v-model="dialog" max-width="740">
            <applicant-checkout-test-numbers
                v-if="dialog"
                @close="dialog = false"
            ></applicant-checkout-test-numbers>
        </v-dialog>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
    name: "applicant-payment",
    components: {
        FormCreditCard: () => import("../Form/FormCreditCard"),
        ApplicantCheckoutTestNumbers: () =>
            import("./ApplicantCheckoutTestNumbers"),
    },
    props: {
        applicant: {
            type: Object,
            required: true,
        },
        application: {
            type: Object,
            required: true,
        },
        valid: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            mode: process.env.NODE_ENV,
            dialog: false,
            // Agree to charging credit card
            agree: false,
            // Card Number, Exp Date, Zip , CCV is valid
            cardValid: false,
            // Errors from the payment processor
            error: "",
            // Cardholder data, initially set from the applicant
            cardholder_name: this.applicant.profile.name,
            cardholder_email: this.applicant.profile.email,
        };
    },
    computed: {
        ...mapGetters("Applications", [
            "paymentConfig",
            "dataLoading",
            "payment",
        ]),
        price() {
            return this.application.product.price;
        },
        iconColor() {
            return this.$vuetify.theme.themes.light.primary;
        },
        paymentDisabled() {
            return (
                !this.cardValid ||
                !this.agree ||
                this.cardholder_email.length == 0 ||
                this.cardholder_name.length == 0 ||
                !this.valid ||
                this.loading
            );
        },
    },
    watch: {
        payment(payment) {
            if (this.$_.get(payment, "meta.status", "") === "succeeded") {
                this.$emit("payment-complete", payment);
            } else {
                this.error = this.$t("applications.payment-error");
                this.$emit("payment-error", payment);
            }
        },
    },
    mounted() {},
    created() {
        // Need to get the stripe key here
        this.getPaymentConfig();
    },
    updated() {},
    methods: {
        ...mapActions("Applications", ["getPaymentConfig", "chargePayment"]),
        fill() {
            this.dialog = true;
        },
        update(result) {
            if (result.error) {
                this.error = result.error;
                this.loading = false;
            } else {
                // no error, so clear any message
                this.error = "";
                // we need the Stripe Token, email to send receipt, amount of charge, and applicant ID to associate charge
                const charge = {
                    token: result.token.id,
                    applicant_email: this.email,
                    amount: this.price,
                    applicant_id: this.applicant.id,
                };
                this.applicantPayment(charge);
            }
        },
        async submitPayment() {
            // resubmitting, clear any error
            this.error = "";
            const result = await this.$refs["credit-card"].createToken();

            if (result.error) {
                this.error = result.error;
            } else {
                const charge = {
                    token: result.token.id,
                    name: this.cardholder_name,
                    email: this.cardholder_email,
                    amount: this.price,
                };
                this.chargePayment(charge);
            }
        },
    },
};
</script>

<style scoped>
</style>