var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.dataLoading && Object.keys(_vm.paymentConfig).length === 0
        ? _c("v-skeleton-loader", { attrs: { type: "card, button" } })
        : _c(
            "div",
            [
              _vm.$vuetify.breakpoint.smAndDown
                ? _c("h3", { staticClass: "mb-2" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("applications.application-payment")) +
                        " "
                    )
                  ])
                : _vm._e(),
              _c(
                "v-card",
                { attrs: { flat: "", disabled: _vm.dataLoading } },
                [
                  _c("form-text-field", {
                    ref: "cardholder_name",
                    attrs: {
                      placeholder: _vm.$t("applications.name-on-card"),
                      rules: [
                        function(v) {
                          return (
                            !!v || _vm.$t("applications.name-on-card-required")
                          )
                        }
                      ],
                      required: "",
                      icon: "person",
                      label: _vm.$t("applications.name-on-card"),
                      name: "cardholder_name"
                    },
                    model: {
                      value: _vm.cardholder_name,
                      callback: function($$v) {
                        _vm.cardholder_name = $$v
                      },
                      expression: "cardholder_name"
                    }
                  }),
                  _c("form-text-field", {
                    attrs: {
                      label: _vm.$t("applications.receipt-email"),
                      placeholder: _vm.$t("applications.enter-email"),
                      icon: "mail_outline",
                      name: "email",
                      required: "",
                      hint: _vm.$t("applications.receipt-email-hint"),
                      "persistent-hint": "",
                      rules: [
                        function(v) {
                          return (
                            v.length == 0 ||
                            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                              v
                            ) ||
                            _vm.$t("applications.email-invalid")
                          )
                        }
                      ]
                    },
                    model: {
                      value: _vm.cardholder_email,
                      callback: function($$v) {
                        _vm.cardholder_email = $$v
                      },
                      expression: "cardholder_email"
                    }
                  }),
                  _vm.mode !== "production"
                    ? _c(
                        "div",
                        { staticClass: "d-flex" },
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mb-n4",
                              attrs: {
                                fab: "",
                                top: "",
                                small: "",
                                right: "",
                                color: "warning darken-2"
                              },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.fill($event)
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("mdi-form-select")])],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("form-credit-card", {
                    ref: "credit-card",
                    attrs: {
                      "stripe-key": _vm.paymentConfig.stripe_key,
                      email: _vm.cardholder_email,
                      name: _vm.cardholder_name
                    },
                    on: {
                      "card:input": function($event) {
                        _vm.cardValid = $event
                      }
                    }
                  }),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "mr-2", attrs: { cols: "1" } },
                        [
                          _c("v-checkbox", {
                            staticStyle: { "margin-top": "-2px" },
                            attrs: { id: "authorize", color: "primary" },
                            model: {
                              value: _vm.agree,
                              callback: function($$v) {
                                _vm.agree = $$v
                              },
                              expression: "agree"
                            }
                          })
                        ],
                        1
                      ),
                      _c("v-col", [
                        _c(
                          "label",
                          {
                            staticClass: "font-weight-light body-1",
                            attrs: { for: "authorize" }
                          },
                          [
                            _vm._v(" I hereby authorize "),
                            _c("span", { staticClass: "font-weight-bold" }, [
                              _vm._v("PACIFIC SCREENING, INC")
                            ]),
                            _vm._v(
                              " to charge the credit card a non-refundable fee of "
                            ),
                            _c("span", { staticClass: "font-weight-bold" }, [
                              _vm._v(_vm._s(_vm.$dollarFormat(_vm.price)))
                            ])
                          ]
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-alert",
                { attrs: { value: _vm.error.length != 0, type: "error" } },
                [_vm._v(_vm._s(_vm.error))]
              ),
              _c(
                "v-layout",
                {
                  staticClass: "mb-2 mt-2",
                  attrs: { row: "", "align-center": "" }
                },
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "secondary",
                        disabled: _vm.paymentDisabled,
                        loading: _vm.dataLoading
                      },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.submitPayment($event)
                        }
                      }
                    },
                    [
                      _c("v-icon", { staticClass: "mr-1" }, [_vm._v("lock")]),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("applications.secure-pay", {
                              price: _vm.$dollarFormat(_vm.price)
                            })
                          ) +
                          " "
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _vm.dataLoading
                        ? _c("psi-content-loader", {
                            attrs: { circle: "", type: "circular" }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "740" },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _vm.dialog
            ? _c("applicant-checkout-test-numbers", {
                on: {
                  close: function($event) {
                    _vm.dialog = false
                  }
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }